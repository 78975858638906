const kategori = {
    listKategori: []
}
const settingKategori = (state = kategori, action) => {
    switch (action.type) {
        case "RESET_LIST_KATEGORI":
          return {
            ...state,
            listKategori: action.listPaket
          }
        case "GET_LIST_KATEGORI":
          state.listKategori = []
          return {
            ...state,
            listKategori: action.listKategori
          }
        default:
          return {
            ...state
          }
    }
}
export default settingKategori