const authReducer = (state = { userRole: "admin" }, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        values: action.data
      }
    case 'LOGOUT':
      return { ...state, values: action.data }
    default:
      return state
  }
}

export default authReducer
