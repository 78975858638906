// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import routeParam from "./routeParam/"
import paketPembelajaran from "./paketPembelajaran"
import setting from "./setting"

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  routeParam,
  paketPembelajaran,
  setting
})

export default rootReducer
