const paket = {
    listPaket: []
}
const paketPembelajaran = (state = paket, action) => {
    switch (action.type) {
        case "RESET_LIST_PAKET":
          return {
            ...state,
            listPaket: action.listPaket
          }
        case "GET_LIST_PAKET":
          state.listPaket = []
          return {
            ...state,
            listPaket: action.listPaket
          }
        default:
          return {
            ...state
          }
    }
}
export default paketPembelajaran