const routeparamState  = []
const RouteParamReducer = (state = routeparamState, action) => {
  switch (action.type) {
    case "GET_PARAM" :
      return {
        ...state,
        param:  action.routeParam
      }
    case "CREATE_PARAM" :
      return {
        ...state,
        param: action.routeParam
      }
    case "REMOVE_PARAM" :
      return {
        ...state,
        param: action.routeParam
      }
    default:
      return state
  }
}
export default RouteParamReducer